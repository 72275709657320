import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../context/AuthContext";
import {
  renderCountryLabel,
  createShipment,
  handleProductChange,
} from "./shipmentUtils";

const Data = () => {
  const { isAuthenticated, loading } = useAuth();
  const [employeeID, setEmployeeID] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  let [address3, setAddress3] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  let [country, setCountry] = useState("");
  const [workmail, setWorkmail] = useState("");
  const [privatemail, setPrivateEmail] = useState("");
  let [worknumber, setWorknumber] = useState("");
  const [persoonnummer, setPersoonnummer] = useState("");
  const [isEditingWorkNumber, setIsEditingWorkNumber] = useState(false);
  const [UPN, setUPN] = useState("");
  const [product, setProduct] = useState("");
  const [label_description, setLabel_description] = useState("");
  const [options, setPackage_options] = useState("");
  const [dataFound, setDataFound] = useState(true);
  const [shipmentresult, setShipmentresult] = useState(null);
  const worknumberRef = useRef(null);

  useEffect(() => {
    if (isAuthenticated) {
      setEmployeeID("");
    }
  }, [isAuthenticated, setEmployeeID]);

  useEffect(() => {
    if (employeeID === "") {
      setName("");
      setAddress("");
      setAddress2("");
      setAddress3("");
      setPostcode("");
      setCity("");
      setCountry("");
      setWorkmail("");
      setPrivateEmail("");
      setWorknumber("");
      setPersoonnummer("");
      setUPN("");
      setShipmentresult("");
      setLabel_description("");
      setProduct("");
    }
  }, [employeeID]);

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      setEmployeeID("");
      setName("");
      setAddress("");
      setAddress2("");
      setAddress3("");
      setPostcode("");
      setCity("");
      setCountry("");
      setWorkmail("");
      setPrivateEmail("");
      setWorknumber("");
      setPersoonnummer("");
      setUPN("");
      setShipmentresult("");

      const apiUrl = process.env.REACT_APP_AFAS_URL;
      const filter = {
        Filters: {
          Filter: [
            {
              "@FilterId": "Filter 1",
              Field: [
                {
                  "@FieldId": "Medewerker",
                  "@OperatorType": 1,
                  "#text": employeeID,
                },
              ],
            },
          ],
        },
      };
      const authToken = process.env.REACT_APP_AFAS_TOKEN;

      const headers = {
        Authorization: `${authToken}`,
      };

      const response = await axios.get(apiUrl, {
        params: {
          filterjson: JSON.stringify(filter),
        },
        headers: headers,
      });

      const responseData = response.data.rows[0];
      // console.log(responseData);
      if (responseData) {
        // setApiData(responseData);
        setDataFound(true);
        setEmployeeID(responseData.Medewerker);
        setName(responseData.Naam);
        setAddress(responseData.Straat);
        setAddress2(JSON.stringify(responseData.Huisnummer));
        setAddress3(responseData.Toev_aan_huisnr);
        setPostcode(responseData.Postcode);
        setCity(responseData.Woonplaats);
        setCountry(responseData.Land);
        setWorkmail(responseData["Gebruiker_e-mail"]);
        setWorknumber(responseData.Mobiel_werk);
        setPersoonnummer(responseData.Mobiel_prive);
        setPrivateEmail(responseData["E-mail_prive"]);
        setUPN(responseData.UPN);
      } else {
        setEmployeeID("Onbekend personeelsnummer");
        setName("");
        setAddress("");
        setAddress2("");
        setAddress3("");
        setPostcode("");
        setCity("");
        setWorkmail("");
        setWorknumber("");
        setPersoonnummer("");
        setPrivateEmail("");
        setUPN("");
        setShipmentresult("");
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  const handleEditClick = () => {
    if (!isEditingWorkNumber) {
      setIsEditingWorkNumber(true);
      setTimeout(() => {
        worknumberRef.current.focus();
      }, 0);
    } else {
      handleWorkNumberSave();
    }
  };

  const handleWorkNumberSave = async () => {
    try {
      const apiUrl = process.env.REACT_APP_AFAS_PUT_URL; // PUT-endpoint
      const authToken = process.env.REACT_APP_AFAS_TOKEN;
      const headers = {
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      };

      const payload = {
        KnPerson: {
          Element: {
            Fields: {
              MatchPer: "0",
              BcCo: employeeID, // Gebruik persoonsnummer
              MbNr: worknumber, // Nieuw werknummer
            },
          },
        },
      };

      const response = await axios.put(apiUrl, payload, { headers });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Succes!",
          text: "Werknummer succesvol opgeslagen!",
          timer: 3000, // Melding verdwijnt na 3 seconden
          showConfirmButton: false,
        });
        setIsEditingWorkNumber(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Fout!",
          text: "Opslaan mislukt. Probeer het later opnieuw.",
          timer: 3000, // Melding verdwijnt na 3 seconden
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Fout bij het opslaan van werknummer:", error);

      Swal.fire({
        icon: "error",
        title: "Fout!",
        text: "Er is een fout opgetreden bij het opslaan van het werknummer.",
        timer: 3000, // Melding verdwijnt na 3 seconden
        showConfirmButton: false,
      });
    }
  };

  const onProductChange = (e) => {
    const selectedProduct = e.target.value;
    setProduct(selectedProduct);
    const options = handleProductChange(selectedProduct, label_description);
    setPackage_options(options);
  };

  const handleCreateShipment = async () => {
    try {
      const country_label = renderCountryLabel(country);
      const result = await createShipment({
        label_description,
        country_label,
        postcode,
        city,
        address,
        address2,
        address3,
        name,
        privatemail,
        worknumber,
        options,
        product,
      });

      // Toon succesmelding
      Swal.fire({
        icon: "success",
        title: "Label Aangemaakt!",
        text: `Label met kenmerk "${label_description}" is succesvol aangemaakt.`,
        timer: 3000, // Melding verdwijnt na 3 seconden
        showConfirmButton: false,
      });

      setShipmentresult(result);
    } catch (error) {
      console.error("Error creating shipment:", error);

      // Toon foutmelding
      Swal.fire({
        icon: "error",
        title: "Fout!",
        text: "Er is een fout opgetreden bij het aanmaken van de zending.",
        timer: 3000, // Melding verdwijnt na 3 seconden
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <div className="main">
        <div className="left-column">
          <h3 className="formheader">Personeelsgegevens</h3>
          <hr></hr>
          <br></br>

          <form className="order-form" onSubmit={handleSearch}>
            <label htmlFor="employeeID">Personeelsnummer:</label>
            <input
              type="text"
              id="employeeID"
              value={employeeID || ""}
              onChange={(e) => setEmployeeID(e.target.value)}
              autoComplete="off"
            />
            <button className="formbutton" type="submit" onClick={handleSearch}>
              <span className="material-icons">search</span>
            </button>
            <label htmlFor="name">Naam:</label>
            <input
              type="text"
              id="name"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="address">Straat:</label>
            <input
              type="text"
              id="address"
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="address">Huisnummer:</label>
            <input
              type="text"
              id="address2"
              value={address2 || ""}
              onChange={(e) => setAddress2(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="address">Toevoeging:</label>
            <input
              type="text"
              id="address3"
              value={address3 ?? ""}
              onChange={(e) => setAddress3(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="postcode">Postcode:</label>
            <input
              type="text"
              id="postcode"
              value={postcode || ""}
              onChange={(e) => setPostcode(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="city">Woonplaats:</label>
            <input
              type="text"
              id="city"
              value={city || ""}
              onChange={(e) => setCity(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="country">Land:</label>
            <input
              type="text"
              id="country"
              value={renderCountryLabel(country)}
              onChange={(e) => setCountry(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="city">Werk e-mailadres:</label>
            <input
              type="text"
              id="workmail"
              value={workmail || ""}
              onChange={(e) => setWorkmail(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <label htmlFor="worknumber">Werk nummer:</label>
            <input
              type="text"
              id="worknumber"
              ref={worknumberRef}
              value={worknumber || ""}
              onChange={(e) => setWorknumber(e.target.value)}
              disabled={!isEditingWorkNumber}
              className={
                !isEditingWorkNumber ? "input-disabled" : "input-active"
              }
            />
            <button
              type="button"
              className="formbutton"
              onClick={handleEditClick}
              disabled={!employeeID.trim()}
            >
              <span className="material-icons">
                {isEditingWorkNumber ? "save" : "edit"}
              </span>
            </button>

            <label htmlFor="UPN">UPN:</label>
            <input
              type="text"
              id="upn"
              value={UPN || ""}
              onChange={(e) => setWorknumber(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <h3 className="myparcel">MyParcel</h3>
            <div></div>
            <div></div>
            <label htmlFor="kenmerk">Kenmerk:</label>
            <input
              type="text"
              id="kenmerk"
              placeholder="Ticketnummer"
              value={label_description || ""}
              onChange={(e) => setLabel_description(e.target.value)}
              autoComplete="off"
              required
              disabled={!name.trim()}
            />
            <div className="required_field">*</div>
            <label htmlFor="product">Product:</label>
            <select id="product" value={product} onChange={onProductChange}>
              <option value="">Selecteer Product</option>
              <option value="Startpakket">Startpakket</option>
              <option value="Chromebook">Chromebook</option>
              <option value="Telefoon">Telefoon</option>
              <option value="Simkaart">Simkaart</option>
              <option value="OTP Token">OTP Token</option>
              <option value="Oplader Telefoon">Oplader Telefoon</option>
              <option value="Oplader Chromebook">Oplader Chromebook</option>
              <option value="Bumper 1 Stuks">Bumper 1 Stuks</option>
              <option value="Bumpers">Bumpers Maximaal 12 Stuks</option>
              <option value="Chromebook sleeve">Chromebook Sleeve</option>
            </select>
            <div className="required_field">*</div>
            <div></div>
            <button
              className="order"
              type="submit"
              onClick={handleCreateShipment}
              disabled={!label_description.trim() || !product}
            >
              MyParcel zending aanmaken
            </button>
          </form>
          <br></br>
          <hr></hr>
          <br></br>
          <div className="required_text">
            Bij het aanmaken van een label zijn velden met een * verplicht.
          </div>
        </div>
        <div className="right-column">
          <h3 className="formheader">Privé Gegevens</h3>
          <hr></hr>
          <br></br>
          <div className="pr-data">
            <label>Prive mobiel:</label>
            <input
              type="text"
              value={persoonnummer}
              onChange={(e) => setCountry(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <div></div>
            <label htmlFor="topdesk-chromebook">Prive email:</label>
            <input
              type="text"
              value={privatemail}
              onChange={(e) => setCountry(e.target.value)}
              disabled="disabled"
            />
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
