import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../config/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  useHandleGoogleSignIn,
  handleGoogleSignOut,
} from "../services/AuthService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const handleGoogleSignIn = useHandleGoogleSignIn();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null)
  const [photo, setPhoto] = useState(null);;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userData) => {
      if (userData) {
        setUserData(userData);
        setPhoto(userData.photoURL);
        setAuthenticated(true);
        setLoading(true);
        const userRef = doc(db, "users", userData.email);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserRole(docSnap.data().role);
          setUserName(docSnap.data().firstname);
          setLoading(false)
        }
      } else {
        setUserData(null);
        setUserRole(null);
        setUserName(null);
        setAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const login = async () => {
    try {
      const userData = await handleGoogleSignIn();
      setUserData(userData);
      setAuthenticated(true);
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  const logout = async () => {
    try {
      await handleGoogleSignOut();
      setUserData(null);
      setUserRole(null);
      setAuthenticated(false);
    } catch (error) {
      console.error("Logout failed: ", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userData,
        userName,
        userRole,
        login,
        logout,
        loading,
        setLoading,
        photo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
